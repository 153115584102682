import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/layout";
import Timeline from "./components/timeline";
import Hierarchy from "./components/hierarchy";
import Map from "./components/map";
import * as React from "react"

import {Authenticator, useTheme, View} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import Constitution from "./components/constitution";
import Logo from "./components/logo";


const components = {
    Header() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <p className="text-white font-medium text-4xl">SKMDO</p>
            </View>
        );
    }
}

function App() {
    return (
        <Authenticator hideSignUp={true} components={components} variation={"modal"} >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Timeline/>}/>
                        <Route path="hierarchy" element={<Hierarchy/>}/>
                        <Route path="constitution" element={<Constitution/>}/>
                        <Route path="map" element={<Map/>}/>
                        <Route path="logo" element={<Logo/>}/>
                        <Route path="*" element={<Timeline/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Authenticator>
    );
}

export default App;
