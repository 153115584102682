import * as React from "react"


export default function Map() {
    return (

        <iframe title="sprinkled map" src="https://www.google.com/maps/d/embed?mid=1uQvjqOY7uR0F3wGrk-rcQKw41gAocaCl"
                style={{width: '100vw', height: '100vh'}} className="lg:pb-16 pb-12"/>

    )
}