import * as React from "react"
import Header from "./header";
import {Outlet} from "react-router-dom";

function Layout() {
    return (
        <div className="flex flex-col min-h-screen font-sans text-gray-900 bgimg">

            <Header />

            <main >
                <Outlet />
            </main>
        </div>
    );
}


export default Layout;