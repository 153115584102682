import data from "../data/cmds.json"
import constitution from "../data/constitution.json"
import {Member, members} from "../data/members";

export class DataService {
    constructor() {
        // do nothing here
    }

    getConstitution() {
        return constitution
    }

    getCommandos() {
        return data.commandos;
    }

    getMembers() {
        return members
    }

    getMember(name: string): Member {
        return this.getMembers().find(member => member.nickname === name) || {
            name: "",
            active: true,
            badge: "",
            nickname: "",
            title: ""
        }
    }

    getHierarchy() {
        let participantList: string[] = []
        // Merge all participant list to one list
        data.commandos.map((element, index) => {
            participantList = participantList.concat(element.participants)
        })

        // Count participation for each member
        // key: member name, value: amount of participated commandos
        const participationCount: Map<string, number> = new Map();
        participantList.forEach((participantName) => {
            const currentCount = participationCount.get(participantName) || 0;
            participationCount.set(participantName, currentCount + 1)
        })

        const ranks = new Map()
        ranks.set(10, new Rank("Generalfeldmarschall", 20, 20))
        ranks.set(9, new Rank("General", 13, 19))
        ranks.set(8, new Rank("Brigadier", 11, 12))
        ranks.set(7, new Rank("Oberst", 9, 10))
        ranks.set(6, new Rank("Major", 7, 8))
        ranks.set(5, new Rank("Leutnant", 6, 6))
        ranks.set(4, new Rank("Vizeleutnant", 4, 5))
        ranks.set(3, new Rank("Wachtmeister", 3, 3))
        ranks.set(2, new Rank("Zugsführer", 2, 2))
        ranks.set(1, new Rank("Gefreiter", 1, 1))

        participationCount.forEach((count, memberName) => {
            ranks.forEach((rank) => {
                if (count >= rank.from && count <= rank.to) {
                    const member = this.getMember(memberName.toLowerCase())
                    member.count = count
                    rank.addMember(member)
                }
            })
        })
        return ranks
    }
}

export class Rank {
    name: string
    from: number
    to: number
    members: Member[] = []

    constructor(name: string, from: number, to: number) {
        this.from = from
        this.to = to
        this.name = name
    }

    addMember(member: Member) {
        this.members.push(member)
    }
}
