import {DataService} from "../services/data";
import * as React from "react"

const dataService = new DataService();

const Timeline = () => {
    return (
        <div className="container mx-auto w-full h-full">
            <div className="relative wrap overflow-hidden p-3 h-full">
                <div className="border-2-2 absolute border-gray-800 h-full border timeline-border"/>

                {dataService.getCommandos().map((commando, index) => {
                    return (
                        <div key={index}
                             className={`mb-3 flex justify-between items-center w-full flex-row ${(index + 1) % 2 !== 0 ? "md:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse" : ""}`}>
                            <div className="order-1 md:w-5/12 lg:w-5/12 xl:w-5/12 visible sm:invisible sm:w-0"/>
                            <div className="order-2 text-white z-20 flex flex-col items-center order-1 bg-gray-800 shadow-xl w-24 h-12 rounded-full">
                                <div className="order-1"><h1
                                    className="mx-auto font-semibold text-lg">{commando.year}</h1></div>
                                <div className="order-2 -mt-2"><h2
                                    className="text-xs">{commando.date_from} - {commando.date_to}</h2></div>
                            </div>
                            <div className={`order-3 sm:w-5/12 md:w-5/12 lg:w-5/12 w-8/12 bg-gray-800 rounded-lg shadow-xl px-6 py-4`}>
                                <div className="flex flex-row">
                                    <div className="flex-1">
                                        <h3 className={`mb-3 font-bold text-white text-xl`}>
                                            {index === 0 ?
                                                <a href="https://www.gasthofzumgreif.at/"
                                                   target="_blank" rel="noreferrer">{commando.location}</a>
                                                : commando.location
                                            }
                                        </h3>
                                    </div>

                                    {index === 0 ?
                                        <a target="_blank" rel="noreferrer"  href={`https://www.gasthofzumgreif.at/`}>
                                            <span className="text-gray-300 h-6 w-6 block">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                                </svg>
                                            </span>
                                        </a>
                                        : null}

                                    <a target="_blank" rel="noreferrer"  href={`https://www.google.com/maps/search/?api=1&query=${commando.map.lat},${commando.map.lng}&z=7`}>
                                        <span className="text-gray-300 h-6 w-6 block">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                <img src={require(`../images/cmds/${commando.year}.jpg`)}/>
                                <div className="flex flex-row mt-2 text-white">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
                                        </svg>
                                    </div>
                                    <div className="text-sm leading-snug tracking-wide">
                                        {commando.participants.join(' | ')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Timeline;