import * as React from "react"
import {DataService} from "../services/data";
import "./constitution.css"

export default function Constitution() {

    function copyLink(e) {
        e.preventDefault();
        const anchorId = e.target.id;
        const baseUrl = "https://www.skmdo.xyz";

        const url = baseUrl + "/constitution#" + anchorId;
        navigator.clipboard.writeText(url).then(function() {
            const tooltip = document.getElementById(anchorId.replace("paragraph", "tooltiptext"));
            tooltip.innerHTML = "Kopiert, Kamerad!";
        }, function() {
            /* clipboard write failed */
        });

    }

    function restoreCopyText(e) {
        const tooltip = document.getElementById(e.target.id.replace("paragraph", "tooltiptext"));
        tooltip.innerHTML = "Kopier den Link";
    }

    function traverse(node, elements, level, lvlIdx) {
        const subs = []
        if (!node.nodes)
            return

        // subsequent number within one level
        let lvlIdxNumber = 1
        node.nodes.forEach(node => {
            const nextLvlIdx = (level === 1 ? "" : lvlIdx + "_") + lvlIdxNumber
            const listItems = traverse(node, elements, level + 1, nextLvlIdx)
            subs.push(formatNode(node, listItems, level, nextLvlIdx))
            lvlIdxNumber += 1
        })
        return <ol className={`level${level}`} >{subs}</ol>
    }

    function formatNode(node, listItems, level, lvlIdx) {
        return <li className={'level' + level}  key={lvlIdx}>
            <div className={'tooltip'}>
                {level <= 2 ?
                    <button id={`paragraph_${lvlIdx}`}  key={`paragraph_${lvlIdx}`} onClick={copyLink} onMouseOut={restoreCopyText} onBlur={ () => void 0 }>
                        <span style={{pointerEvents: "none"}} key={`tooltiptext_${lvlIdx}`} className="tooltiptext" id={`tooltiptext_${lvlIdx}`}>Kopier den Link</span>

                        <svg style={{pointerEvents: "none"}}  className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
                        </svg>
                    </button>
                    : null }
            </div>
            <a href={`#paragraph_${lvlIdx}`}>{node.text}</a><ol>{listItems}</ol></li>
    }

    const dataService = new DataService();
    const constitution = dataService.getConstitution()

    const elements = []
    const root = traverse(constitution, elements, 1, "")

    return (
        <div className="flex justify-center p-3">
            <div className="statuten overflow-hidden shadow rounded-lg px-4 py-5 sm:p-6 lg:w-1/2 bg-white ">
                {root}
            </div>
        </div>
    )
}