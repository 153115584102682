import * as React from "react"
import {NavLink} from "react-router-dom";
import {Authenticator} from "@aws-amplify/ui-react";


function Header() {

    const [navbarOpen, setNavbarOpen] = React.useState(false);

    return (
        <header>
            <nav className="bg-gray-800">
                <div className="mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <button
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                                aria-label="Main menu" aria-expanded="false" onClick={() => setNavbarOpen(!navbarOpen)}>

                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M4 6h16M4 12h16M4 18h16"/>
                                </svg>

                                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <a href="https://www.skmdo.xyz" className="flex items-center">
                                <img src={require("../images/logo/small.png")} className="mr-3 h-6 sm:h-9"
                                     alt="SKMDO Logo"/>
                                <span
                                    className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">SKMDO</span>
                            </a>

                            <div className="hidden sm:block sm:ml-6">
                                <nav id="navbar" className="flex">
                                    <NavLink to="/"
                                             className="px-3 py-2 rounded-md text-sm bg-gray-900 font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Chronik</NavLink>

                                    <NavLink to="/hierarchy"
                                             className="ml-4 px-3 py-2 rounded-md text-sm bg-gray-900 font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Ränge</NavLink>

                                    <NavLink to="/constitution"
                                          className="ml-4 px-3 py-2 rounded-md text-sm bg-gray-900 font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Statuten</NavLink>

                                    <NavLink to="/map"
                                          className="ml-4 px-3 py-2 rounded-md text-sm bg-gray-900 font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Karte</NavLink>

                                    <NavLink to="/logo"
                                          className="ml-4 px-3 py-2 rounded-md text-sm bg-gray-900 font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Wappen</NavLink>

                                </nav>
                            </div>
                        </div>
                        <Authenticator>
                            {({signOut, user}) => (
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                                    {user?.attributes?.nickname &&
                                        <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                                            src={require(`../images/avatar/${user?.attributes?.nickname}.jpg`)} alt="Bordered avatar" />
                                    }
                                    <a onClick={signOut} href="#"
                                       className="p-1 text-spreng border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                                       aria-label="Notifications"
                                    >

                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                                        </svg>
                                    </a>
                                </div>
                            )}
                        </Authenticator>
                    </div>
                </div>

                {/*Mobile menu, toggle classes based on menu state.

            Menu open: "block", Menu closed: "hidden"*/}


                <div className={(navbarOpen ? " block" : " hidden sm:hidden")}>
                    <nav id="navbar" className="px-2 pt-2 pb-3">
                        <NavLink to="/"
                              className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                              onClick={() => setNavbarOpen(!navbarOpen)}>
                            Chronik
                        </NavLink>

                        <NavLink to="/hierarchy"
                              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                              onClick={() => setNavbarOpen(!navbarOpen)}>
                            Ränge
                        </NavLink>

                        <NavLink to="/constitution"
                              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                              onClick={() => setNavbarOpen(!navbarOpen)}>
                            Statuten
                        </NavLink>

                        <NavLink to="/map"
                              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                              onClick={() => setNavbarOpen(!navbarOpen)}>
                            Karte
                        </NavLink>

                        <NavLink to="/logo"
                              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                              onClick={() => setNavbarOpen(!navbarOpen)}>
                            Wappen
                        </NavLink>
                    </nav>
                </div>

            </nav>
        </header>
    );
}

export default Header;