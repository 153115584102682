export interface Member {
    nickname: string
    name: string
    title: string
    active: boolean
    badge: string,
    count?: number
}

export const members: Member[] = [
    {
        "nickname": "david",
        "name": "David Honk",
        "title": "Referent für Riesentitten, Architektur und Desorientierung",
        "badge": "medal-39",
        "active": true
    },
    {
        "nickname": "fritz",
        "name": "Fritz",
        "title": "Referent für Elektro, Milfs und andere Altwaren",
        "badge": "chevron-14",
        "active": false
    },
    {
        "nickname": "thomas",
        "name": "Thomi",
        "title": "Referent für Vandalismus und Verschwörung sowie Weiber und Sprit",
        "badge": "chevron-13",
        "active": true
    },
    {
        "nickname": "jürgen",
        "name": "Jürgen",
        "title": "Referent für neuen Schwung und Kopfschmuck sowie Pflege, Zigaretten und Gaunerei",
        "badge": "chevron-1",
        "active": true
    },
    {
        "nickname": "flo",
        "name": "Mad Doc",
        "title": "Referent für die Behandlung von Geisteskranken, Irren, Schwachsinnigen etc. und anderem unnützen Wissen. Außerdem ist er seines Glaubens Herr über Gurker, Rapid, Kultur, Metaphysik und Mittelerde.",
        "badge": "chevron-11",
        "active": true
    },
    {
        "nickname": "andi",
        "name": "Andi",
        "title": "Referent für mentalen Aufbau & unnötigen Aktionismus (z.B. Tennis)",
        "badge": "chevron-4",
        "active": true
    },
    {
        "nickname": "stephan",
        "name": "Tafte",
        "title": "Referent für Tageszeitungen sowie Stornierung bzw. ungeplante Abreise",
        "badge": "chevron-4",
        "active": true
    },
    {
        "nickname": "gegge",
        "name": "Gegge",
        "title": "Referent für die Gleichstellung des Mannes und No Ma`am-Fraktion sowie den Nachlass 'Diego Armando Maradonna'",
        "badge": "chevron-9",
        "active": false
    },
    {
        "nickname": "falco",
        "name": "Falco",
        "title": "Referat Insomnia",
        "badge": "chevron-18",
        "active": false
    },
    {
        "nickname": "stefan",
        "name": "Weiße",
        "title": "Referent für Anatomie von Pferde ähnlichen Geschlechtsteilen und Sport sowie reiches Weibsvolk",
        "badge": "chevron-1",
        "active": true
    },
    {
        "nickname": "richard",
        "name": "Rich",
        "title": " Referent für übermäßigen Bierkonsum und die Behandlung von Tieren, außer Hunde, Katzen und Pferde (z. B. Besorgung von Blauspray)",
        "badge": "chevron-10",
        "active": true
    },
    {
        "nickname": "lacky",
        "name": "Lacky",
        "title": "Referent für Personenschutz",
        "badge": "chevron-18",
        "active": false
    },
    {
        "nickname": "akis",
        "name": "Akis",
        "title": "Referent für Stromversorgung und Hallenbäder bzw. Kläranlagen sowie Entwicklungshilfe in Mellach",
        "badge": "chevron-12",
        "active": true
    },
    {
        "nickname": "geri",
        "name": "Geri",
        "title": "Referent für Unzuverlässigkeit & Dentale Supervision ",
        "badge": "chevron-18",
        "active": false
    },
    {
        "nickname": "martin",
        "name": "Martin",
        "title": "Referent für Kleinwüchsige und Exibitionismus sowie Opfer häuslicher Gewalt",
        "badge": "chevron-4",
        "active": true
    }];