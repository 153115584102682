import * as React from "react"
import {DataService} from "../services/data";
import {Member} from "../data/members";

function Hierarchy() {


    const dataService = new DataService();
    const hierarchy = dataService.getHierarchy();

    return (
        <>
            <div className="flex flex-col max-w-7xl mx-auto p-4 sm:px-6 lg:px-8 rounded-lg overflow-x-scroll lg:overflow-x-auto">
                {Array.from(hierarchy).map(([rankingIndex, rank]) => {

                    const members = rank.members
                    return (<div key={`ranking${rankingIndex}`} className="flex flex-col">
                        <div className="order-1 text-lg text-center lg:text-3xl font-extrabold text-gray-800">

                            <span className="p-1 h-4 w-4 rounded-full ring-2 ring-white text-gray-900 text-sm bg-spreng">
                                { rank.from == rank.to ? rank.to : `${rank.from}-${rank.to}`}
                            </span>
                        </div>
                        <div className="order-1 text-lg text-center lg:text-3xl font-extrabold text-gray-800">
                            {rank.name}
                        </div>
                        <ul className={`order-2 flex flex-row ${members.length == 1 ? "mt-0" : "mt-5" } justify-center`}>
                            <div className={`-mt-5 border-l-4 absolute ${members.length == 1 ? "h-0" : "h-5" } border-white`}>
                            </div>
                            {members.map((value: Member, memberIndex: number) => {
                                const styleFirst = {left: "50%", right: "0px"}
                                const styleLast = {left: "0px", right: "50%"}
                                const styleMiddle = {left: "-50%", right: "-50%"}
                                let styleFinal

                                if (memberIndex == 0 && members.length - 1 == 0)
                                    styleFinal = {}
                                else if (memberIndex === members.length - 1)
                                    styleFinal = styleLast
                                else if (memberIndex != 0 && memberIndex < members.length)
                                    styleFinal = styleMiddle
                                else
                                    styleFinal = styleFirst

                                /*let fluid = images.allFile.edges.find((element) => element.node.base === `${value.shortName.toLowerCase()}.jpg`).node.childImageSharp.fluid*/
                                const member : Member = value
                                return (
                                    <li key={`member${rankingIndex}${memberIndex}`} className="relative p-6">
                                        <div className={`border-t-4 absolute h-8 border-white top-0`}
                                             style={styleFinal}>
                                        </div>
                                        <div className="relative flex justify-center">
                                            <div className="-mt-6 border-l-4 absolute h-6 border-white top-0">
                                            </div>
                                            <div className="text-center">
                                                <div className="flex flex-col justify-center items-center w-24 lg:w-40">
                                                    <div className="">
                                                            <span key={`member`} className="inline-block relative">
                                                                <img className="h-24 w-24 rounded-full border-solid border-white border-2" src={require(`../images/avatar/${value.nickname.toLowerCase()}.jpg`)} />
                                                                <span
                                                                    className={`absolute top-0 right-1 block h-4 w-4 rounded-full ring-2 ring-white ${member.active ? 'bg-spreng' : 'bg-gray-300'}`}>
                                                                    <img src={require(`../images/badges/${member.badge}.svg`)} alt="Badge"/>
                                                                 </span>
                                                            {/*<Img className="h-24 w-24 rounded-full border-solid border-white border-2"
                                                                 fluid={fluid}
                                                                 alt={value.name}/>
                                                                 <span
                                                                     className={`absolute top-0 right-1 block h-4 w-4 rounded-full ring-2 ring-white ${member.active ? 'bg-spreng' : 'bg-gray-300'}`}>
                                                                    <img src={`../${member.badge}.svg`} alt="test"/>
                                                                 </span>*/}
                                                            </span>
                                                    </div>
                                                    <div className="-mt-1 text-black">
                                                        { rank.from == rank.to ? null : <span className="text-xs rounded-full ring-2 ring-white text-gray-900 bg-spreng text-center font-extrabold">{member.count}</span> }
                                                        <p className="text-sm font-bold">{member.name}</p>
                                                        <p className="text-sm">{member.title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>)
                })}
                <div className="text-white text-center">
                    <span
                        className={`h-4 w-4 rounded-full ring-2 ring-white bg-spreng`}>&nbsp;&nbsp;&nbsp;&nbsp;</span> = Aktiv
                    <span
                        className={`h-4 w-4 ml-2 rounded-full ring-2 ring-white bg-gray-300`}>&nbsp;&nbsp;&nbsp;&nbsp;</span> = Außer Dienst
                </div>
                <div className="text-white text-center">Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            </div>
        </>
    )
}

export default Hierarchy;