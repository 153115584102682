import React from "react"


export default function Logo() {
    return (
        <div className="flex justify-center pt-3">
            <div className="flex-col overflow-hidden shadow rounded-lg px-4 py-5 sm:p-6 lg:w-1/2 bg-white ">
                <div className="flex-row text-center">
                    <p>Download
                        <a className="font-medium" href={`./logo/SKMDO-logo.svg`} download>: SVG</a>
                        <a className="font-medium" href={`./logo/SKMDO-logo.jpg`} download> - JPG</a>
                        <a className="font-medium" href={`./logo/SKMDO-logo.png`} download> - PNG</a>
                        <a className="font-medium" href={`./logo/SKMDO-logo.ai`} download> - AI</a>
                    </p>
                </div>
                <div className="flex-row ">
                    <img src={`./logo/SKMDO-logo.jpg`}/>
                </div>
            </div>

        </div>
    )
}